import axios from 'axios'

const wechat_url = 'https://microservice.mxmmxm.cn/wechat';

function configuration(url) {
    console.log("url: " + url)
    let request_config_url = wechat_url + '/config/init';
    console.log("request url: " + request_config_url)
    let form = new FormData();
    form.append('url', url);
    return axios.post(request_config_url, form).then(response => {
        console.log("config" + JSON.stringify(response))
        return response.data;
    }).catch( (error, description )=> {
        console.error(error + ", " + description)
        return {responseCode: 'RESPONSE_ERROR', description: 'Fail to process the request'}
    });
}

function openid() {
    let openid = localStorage.getItem('openid');
    if (openid == null || openid === '') {
        return null;
    }
    return openid;
}

function openidbycode(code) {
    let request_openid_url = 'https://microservice.mxmmxm.cn/reception/wechat/openid/bycode';
    let form = new FormData();
    form.append('code', code);
    return axios.post(request_openid_url, form).then(response => {
        return response.data;
    }).catch(() => {
        return {responseCode: 'RESPONSE_ERROR', description: 'Fail to process the request'}
    })
}

function subscriptionExist(openid) {
    let request_url = "https://microservice.mxmmxm.cn/reception/wechat/subscription/exist";
    let form = new FormData();
    form.append("openId", openid);
    return axios.post(request_url, form).then(response => {
        return response.data;
    }).catch(() => {
        return {responseCode: 'RESPONSE_ERROR', description: 'Fail to process the request'};
    })
}


export const wechatservice = {
    configuration, openid, openidbycode, subscriptionExist
};